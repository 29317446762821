<template>
    <div class="file_detailsWpr">
        <!-- <div class="header">
            <h2>{{ file.name }}</h2>
            <ul>
                <li><button type="button" v-clipboard:copy="file.short_link" v-clipboard:success="onCopy" v-clipboard:error="onError"><i class="fas fa-link"></i></button></li>
                <li><button type="button" @click="downloadFile(file)"><i class="fas fa-download"></i></button></li>
                <li><button type="button" @click="deleteFiles([file.id])"><i class="fas fa-trash-alt"></i></button></li>
            </ul>
        </div> -->
        <div class="thumb">
            <div class="header">
                <h3 class="sub_heading2 m-0 title">{{ file.name }}</h3>
                <ul>
                    <li><button type="button" v-clipboard:copy="file.short_link" v-clipboard:success="onCopy" v-clipboard:error="onError"><i class="fas fa-link"></i></button></li>
                    <li><button type="button" @click="downloadFile(file)"><i class="fas fa-download"></i></button></li>
                    <li><button type="button" @click="deleteFiles([file.id])"><i class="fas fa-trash-alt"></i></button></li>
                </ul>
            </div>
            <video width="100%" controls v-if="file.is_video">
                <source :src="file.path" :type="`video/${file.ext}`">
                Your browser does not support the video tag.
            </video>
            <iframe v-else-if="file.is_doc_file" :src="`https://docs.google.com/gview?url=${file.path}&embedded=true`" frameborder="0" style="width: 100%; height: 100vh;"></iframe>
            <img v-else :src="file.thumbnail">
            <ul class="stat">
                <li><i class="fas fa-binoculars"></i>{{ file.views }} Views</li>
                <li><i class="fas fa-download"></i>{{ file.downloads }} Downloads</li>
            </ul>
        </div>
        <div class="total_fileinfo">
            <div class="file_option">
                <h3 class="m-0 pb-2">File Options</h3>
                <ul>
                    <li>
                        <label for="download-v1" class="switch_option capsule_btn">
                            <h5>Download Permission</h5>
                            <input type="checkbox" id="download-v1" :true-value="1" :false-value="0" hidden v-model="form.can_download"  @change="updatePermission({ id: file.id, permission: 'can_download', value: form.can_download })">
                            <div><span></span></div>
                        </label>
                    </li>
                    <li>
                        <label for="link-v1" class="switch_option capsule_btn">
                            <h5>Copy Link Permission</h5>
                            <input type="checkbox" id="link-v1" :true-value="1" :false-value="0" hidden v-model="form.can_share"  @change="updatePermission({ id: file.id, permission: 'can_share', value: form.can_share })">
                            <div><span></span></div>
                        </label>
                    </li>
                    <li>
                        <label for="permission-v1" class="switch_option capsule_btn">
                            <h5>View File Permission</h5>
                            <input type="checkbox" id="permission-v1" :true-value="1" :false-value="0" hidden v-model="form.can_view"  @change="updatePermission({ id: file.id, permission: 'can_view', value: form.can_view })">
                            <div><span></span></div>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="file_status">
                <h3 class="m-0 pb-2">File Details</h3>
                <table>
                    <tr>
                        <th>Type</th>
                        <td>{{ file.ext }}</td>
                    </tr>
                    <tr>
                        <th>Size</th>
                        <td>{{ file.size }}</td>
                    </tr>
                    <tr>
                        <th>Owner</th>
                        <td>{{ file.owner }}</td>
                    </tr>
                    <tr>
                        <th>Modified</th>
                        <td>{{ moment.utc(file.created_at).local().format('MM/DD/YYYY h:m A') }} by me</td>
                    </tr>
                    <tr>
                        <th>Opened</th>
                        <td>{{ moment().format('MM/DD/YYYY h:m A') }}</td>
                    </tr>
                    <tr>
                        <th>Created</th>
                        <td>{{ moment.utc(file.created_at).local().format('MM/DD/YYYY h:m A') }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import Toastr from '@/utils/Toastr'

    import { mapActions } from 'vuex'

    export default {
        name: 'File Preview',

        data () {
            return {
                form: {
                    can_download: 0,
                    can_share: 0,
                    can_view: 0,
                },
                moment,
            };
        },

        props: {
            file: Object
        },

        mounted () {
            const vm = this;

            vm.form = {
                          can_download: vm.file.can_download,
                          can_share: vm.file.can_share,
                          can_view: vm.file.can_view,
                      };

            vm.updateStats({ id: vm.file.id, stats: 'views' });
        },

        methods: {
            ...mapActions({
                updateStats: 'fileModule/updateStats',
                deleteFiles: 'fileModule/deleteFiles',
                downloadFile: 'fileModule/downloadFile',
                updatePermission: 'fileModule/updatePermission',
            }),

            onCopy () {
                Toastr.error('Failed to copy file link!');
            },

            onError () {
                Toastr.success('File link copied successfully!');
            },

        },
    }
</script>

<style scoped>
    .title{
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;  
        overflow: hidden;
    }
</style>
